// eslint-disable-next-line import/no-anonymous-default-export
import { lazy } from "react";
import Loadable from "src/layouts/full/shared/loadable/Loadable";

const PlayerRegistration = Loadable(
  lazy(() => import("src/views/apps/playerRegistration"))
);

const PlayerDetail = Loadable(
  lazy(() => import("src/views/apps/playerRegistration/PlayerDetail"))
);
const PlayerKitting = Loadable(
  lazy(() => import("src/views/apps/playerKitting"))
);
const EditPlayerKitting = Loadable(
  lazy(() => import("src/views/apps/playerKitting/Edit"))
);
const PlayerPerformances = Loadable(
  lazy(() => import("src/views/apps/playerPerformances"))
);
const EditPlayerPerformances = Loadable(
  lazy(() => import("src/views/apps/playerPerformances/Edit"))
);
const Visa = Loadable(lazy(() => import("src/views/apps/visa")));
const Instructions = Loadable(
  lazy(() => import("src/views/apps/instructions"))
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: "",
  children: [
    {
      path: "playerRegistration",
      children: [
        {
          path: "",
          element: <PlayerRegistration />,
        },

        {
          path: ":id/player/:pid",
          element: <PlayerDetail />,
        },
      ],
    },
    {
      path: "kitting/player",
      children: [
        {
          path: "",
          element: <PlayerKitting />,
        },
        {
          path: "",
          element: <PlayerKitting />,
        },

        {
          path: "edit",
          element: <EditPlayerKitting />,
        },
      ],
    },
    {
      path: "kitting/coach",
      children: [
        {
          path: "",
          element: <PlayerKitting />,
        },

        {
          path: "edit",
          element: <EditPlayerKitting />,
        },
      ],
    },
    {
      path: "playerPerformance",
      children: [
        {
          path: "",
          element: <PlayerPerformances />,
        },

        {
          path: "edit",
          element: <EditPlayerPerformances />,
        },
      ],
    },
    {
      path: "visaPassport",
      children: [
        {
          path: "",
          element: <Visa />,
        },
      ],
    },
    {
      path: "instructions",
      children: [
        {
          path: "",
          element: <Instructions />,
        },
      ],
    },
  ],
};
